import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { EntityProps, Payload, ExperimentValues } from 'types';
import { Extractor } from 'components';

export interface CustomExtractorProps {
  task: number;
  type: number;
  setExpProps(payload: Payload): void;
  expValues: ExperimentValues;
  setBuildDisabledHandler(value: boolean): void;
}

export const ExtractorTraining: FC<CustomExtractorProps> = ({
  expValues,
  setExpProps,
  setBuildDisabledHandler,
}) => {
  const [disableNextStep, setDisableNextStep] = useState(
    !expValues.config.entities ||
      (expValues.config.entities as EntityProps[]).length === 0
  );

  const onEntitiesChange = useCallback(
    (entities: EntityProps[]) => {
      if (!expValues.config) {
        return;
      }

      setExpProps({
        config: { ...expValues.config, entities },
      });

      entities.length > 0
        ? setDisableNextStep(false)
        : setDisableNextStep(true);
    },
    // eslint-disable-next-line
    [setExpProps, expValues]
  );

  useEffect(() => {
    setBuildDisabledHandler(disableNextStep);
  }, [setBuildDisabledHandler, disableNextStep]);

  return (
    <Box borderRadius="8px">
      <Extractor
        onEntitiesChange={onEntitiesChange}
        initialEntities={expValues.config.entities as EntityProps[]}
      />
    </Box>
  );
};
