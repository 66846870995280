import React, { useState } from 'react';
import { TextField, Box, Button, Tabs, Tab } from '@material-ui/core';
import { Text } from 'components/Text';
import { Category } from './ClassificationSettings';
import { EditableItem } from './EditableItem';
import useStyles from './ClassificationSettings.styles';
import { DeleteIcon } from 'assets';

interface CategorySettingsProps {
  initialValues: Category;
  onSave(data: Category): void;
  style?: React.CSSProperties;
  classificationType: 'text' | 'image' | 'audio';
}

export const CategorySettings = ({
  initialValues,
  onSave,
  style,
  classificationType,
}: CategorySettingsProps) => {
  const classes = useStyles();
  const [data, setData] = useState(initialValues);
  const [newExample, setNewExample] = useState('');
  const [tab, setTab] = useState(0);
  const exampleList = (tab === 0 ? data.examples : data.negativeExamples) || [];
  const exampleListKey = tab === 0 ? 'examples' : 'negativeExamples';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleExampleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!newExample) return;

    setData((prev) => ({
      ...prev,
      [exampleListKey]: [newExample, ...exampleList], // Insert at the top
    }));
    setNewExample('');
  };

  const handleExampleChange = (idx: number, text: string) => {
    if (!text) return;
    setData((prev) => {
      const exampleCopy = [...exampleList];
      exampleCopy[idx] = text;
      return { ...prev, [exampleListKey]: exampleCopy };
    });
  };

  const handleExampleRemove = (idx: number) => {
    const newExamples = exampleList.filter((_, i) => i !== idx);
    setData((prev) => ({ ...prev, [exampleListKey]: newExamples }));
  };

  const handleSave = () => {
    if (!data.name) return;

    onSave(data);
  };

  const showExamples = classificationType === 'text';

  return (
    <Box display="flex" flexDirection="column" textAlign="left" style={style}>
      <Box padding="24px" display="flex" flexDirection="column" gridGap="14px">
        <Text variant="h4">Category Name*</Text>
        <TextField
          name="name"
          variant="outlined"
          placeholder="Enter category name"
          onChange={handleChange}
          size="medium"
          value={data.name || ''}
          required
          error={!data.name}
          helperText={!data.name ? 'Category name cannot be empty' : ''}
        />
        <div></div>
        <Text variant="h4">Category Description</Text>
        <TextField
          name="description"
          variant="outlined"
          placeholder="Enter category description"
          onChange={handleChange}
          size="medium"
          value={data.description || ''}
          multiline
          rows={3}
        />
      </Box>

      {showExamples && (
        <>
          <Tabs
            value={tab}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={(e, i) => setTab(i)}
            variant="fullWidth"
          >
            <Tab label="Positive Examples" />
            <Tab label="Negative Examples" />
          </Tabs>

          <Box
            padding="24px"
            display="flex"
            flexDirection="column"
            gridGap="14px"
          >
            <Box
              display="flex"
              flexDirection="column"
              gridGap="14px"
              textAlign="left"
              component="form"
              onSubmit={handleExampleSubmit}
            >
              <Box display="flex" gridGap="8px">
                <TextField
                  variant="outlined"
                  placeholder="Add an example"
                  onChange={(e) => setNewExample(e.target.value)}
                  size="medium"
                  value={newExample}
                  style={{ flexGrow: 1 }}
                />
                <Button
                  type="submit"
                  variant="outlined"
                  color="secondary"
                  size="medium"
                >
                  Add
                </Button>
              </Box>

              {!exampleList.length && (
                <Text className={classes.emptyMessage}>No examples yet</Text>
              )}

              {exampleList.map((example, idx) => (
                <EditableItem
                  key={example}
                  text={example}
                  options={[
                    {
                      icon: (
                        <DeleteIcon
                          className={classes.icon}
                          width={28}
                          height={28}
                        />
                      ),
                      onClick: () => handleExampleRemove(idx),
                      label: 'Remove',
                    },
                  ]}
                  onChange={(text) => handleExampleChange(idx, text)}
                />
              ))}
            </Box>
          </Box>
        </>
      )}

      <Box
        padding="24px"
        position="sticky"
        bottom={0}
        bgcolor="background.paper"
      >
        <Button
          onClick={handleSave}
          color="secondary"
          variant="contained"
          size="medium"
          style={{ width: '100%' }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
