import { makeStyles } from '@material-ui/core';
import { scrollbarStyle } from 'material';
import { colors } from 'material/colors';

const SPACING = 24;

export default makeStyles(({ breakpoints }) => ({
  card: {
    background: (props: any) => props.colorBox,
    boxShadow: '0 8px 16px 0 rgb(72 79 121 / 15%)',
    borderRadius: '10px',
  },
  testWrapper: {
    padding: 0,
    display: 'grid',
    gap: 32,
    [breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto !important',
    },
  },
  inputWrapper: {
    boxSizing: 'border-box',
    width: '100%',
    padding: SPACING,
    background: (props: any) => props.colorBox,
    height: 'max-content',
  },
  resultBody: {
    padding: SPACING,
    ...scrollbarStyle,
  },
  text: {
    padding: SPACING,
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: '#848aa9',
  },
  subtitle: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  textArea: {
    marginTop: SPACING,
    fontFamily: 'Poppins',
    boxSizing: 'border-box',
    overflow: 'auto !important',
    height: '320px !important',
    width: '100%',
    border: '1px solid #d8ddeb',
    padding: '15px',
    borderRadius: '8px',
    '&:focus': {
      border: '1px solid #afb3bd',
      outline: '1px solid #afb3bd',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  button: {
    marginTop: 20,
  },
  alertWrapper: {
    marginTop: '16px',
  },
}));
