import React, { useState } from 'react';
import {
  Typography,
  Box,
  Chip,
  Card,
  LinearProgress,
  Divider,
  IconButton,
  Collapse,
} from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import { CategoryResponse, PredictResponse } from 'types';
import useStyles from './LLMClassificationResponseBox.styles';

interface LLMClassificationResponseBoxProps {
  response: PredictResponse;
}

export const LLMClassificationResponseBox = ({
  response,
}: LLMClassificationResponseBoxProps) => {
  const classes = useStyles();
  const [filter, setFilter] = useState<'all' | 'matched' | 'not_matched'>(
    'matched'
  );
  const [expandedCategories, setExpandedCategories] = useState<Set<string>>(
    new Set()
  );

  const handleFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newFilter: 'all' | 'matched' | 'not_matched' | null
  ) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  const handleExpandClick = (categoryName: string) => {
    setExpandedCategories((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(categoryName)) {
        newSet.delete(categoryName);
      } else {
        newSet.add(categoryName);
      }
      return newSet;
    });
  };

  const filteredCategories = (response.result as CategoryResponse[]).filter(
    (category) => {
      if (filter === 'all') return true;
      if (filter === 'matched') return category.match;
      return !category.match;
    }
  );

  return (
    <Box>
      <Box mb={2}>
        <ToggleButtonGroup
          value={filter}
          exclusive
          onChange={handleFilterChange}
          aria-label="category filter"
        >
          <ToggleButton value="all" aria-label="show all">
            All
          </ToggleButton>
          <ToggleButton value="matched" aria-label="show matched">
            Matched
          </ToggleButton>
          <ToggleButton value="not_matched" aria-label="show not matched">
            Not Matched
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {filteredCategories.length > 0 ? (
        filteredCategories.map((category) => {
          const newScoreVal = Math.round(category.score * 100);
          return (
            <Card key={category.name} className={classes.card}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="subtitle1"
                  className={classes.categoryName}
                >
                  {category.name}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Box display="flex" alignItems="center" mr={1}>
                    <Typography variant="body2" className={classes.score}>
                      {newScoreVal}%
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={newScoreVal}
                      color={category.match ? 'primary' : 'secondary'}
                      className={classes.progress}
                    />
                  </Box>
                  <Chip
                    size="small"
                    label={category.match ? 'Matched' : 'Not Matched'}
                    color={category.match ? 'primary' : 'default'}
                    className={classes.chip}
                  />
                  {category.reasoning && (
                    <IconButton
                      className={`${classes.expand} ${
                        expandedCategories.has(category.name)
                          ? classes.expandOpen
                          : ''
                      }`}
                      onClick={() => handleExpandClick(category.name)}
                      aria-expanded={expandedCategories.has(category.name)}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
              <Collapse
                in={expandedCategories.has(category.name)}
                timeout="auto"
                unmountOnExit
              >
                <Divider className={classes.divider} />
                <ReactMarkdown
                  remarkPlugins={[remarkGfm, remarkBreaks]}
                  linkTarget="_blank"
                  className={classes.reasoning}
                >
                  {category.reasoning}
                </ReactMarkdown>
              </Collapse>
            </Card>
          );
        })
      ) : (
        <Typography
          variant="body1"
          align="center"
          className={classes.emptyMessage}
        >
          No categories found for the selected filter.
        </Typography>
      )}
    </Box>
  );
};
