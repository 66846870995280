/* eslint-disable */
import { Box, Button, Grid, Link, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { GenericModalState } from 'components/GenericModal/GenericModal';
import { GenericResponseMessageR2R2 } from 'components/GenericResponseMessageR2R2';
import { SimpleMessage } from 'components/SimpleMessage';
import { AntTab, AntTabs } from 'components/Tabs';
import { Text } from 'components';
import { currentModelListState, areAllModelsDoneSelector } from 'store/models';
import { CancelFooter } from './CancelFooter';
import { Dataset } from './Dataset';
import { Details } from './Details';
import { ModelSelectModal } from './ModelSelectModal/ModelSelectModal';
import { ModelView } from './ModelView';
import { getCustomModelList } from './useModel';
import useStyles from './view-experiment-body.styles';
import { getEnv } from 'services/env';
import {
  Experiment,
  STATUS_PENDING,
  STATUS_IN_QUEUE,
  STATUS_RUNNING,
  EXPERIMENT_VISIBILITY_NUM,
  Company,
  EXPERIMENT_TASK,
} from 'types';
import { useAuthenticationContext, useExperimentContext } from 'context';
import { http } from 'services/http';
import { updateSendNotification } from 'services';

interface Props {
  experiment: Experiment;
}

const ENVIRONMENT = getEnv('REACT_APP_ENVIRONMENT');
const ENV_IS_CLOUD = ENVIRONMENT === 'cloud';
const CLOUD_LOADING_MESSAGE =
  'The experiment is acquiring execution resources and it will start shortly';

export const ViewExperimentBody = ({ experiment }: Props) => {
  const classes = useStyles();
  const { user } = useAuthenticationContext();
  const { modelId } = useParams<{ modelId?: string }>();
  const [tab, setTab] = useState(0);

  const { model, setModel, addModelLogs } = useExperimentContext();

  const areAllModelsDone = useRecoilValue(areAllModelsDoneSelector);
  const [models, setModels] = useRecoilState(currentModelListState);
  const setModalState = useSetRecoilState(GenericModalState);
  const [company, setCompany] = useState<Company>();

  useEffect(() => {
    const modelsUpdated = getCustomModelList(experiment);
    setModels(modelsUpdated);
    if (modelId) {
      const model = modelsUpdated.find((m) => m.id === modelId);
      setModel(model);
    } else {
      setModel(modelsUpdated[0]);
    }
  }, [experiment, setModels, setModel, modelId]);

  useEffect(
    () => () => {
      setModels([]);
      setModel(undefined);
    },
    [setModel, setModels]
  );

  const showModelView = useMemo(
    () => models.some(({ status }) => status >= STATUS_RUNNING),
    [models]
  );

  const handleClick = useCallback(() => {
    setModalState(true);
  }, [setModalState]);

  const handleChange = useCallback(
    (e, newValue) => {
      setTab(newValue);
    },
    [setTab]
  );

  const allModelsWaiting = useMemo(
    () => experiment.models.every((el) => el.status < STATUS_RUNNING),
    [experiment]
  );

  useEffect(() => {
    if (!model || !Object.keys(model).length) return;

    addModelLogs(model.id);
  }, [model]);

  useEffect(() => {
    http.get('company/').then((res) => {
      setCompany(res as Company);
    });
  }, []);

  const isOwner = useMemo(() => user?.id === experiment.id_user, [
    user,
    experiment,
  ]);

  const isCompanyOwner = useMemo(() => company?.id_owner === user?.id, []);

  const isCommunity = useMemo(
    () => experiment?.visibility === EXPERIMENT_VISIBILITY_NUM.COMMUNITY,
    [experiment]
  );

  const isOrganization = useMemo(
    () => experiment?.visibility === EXPERIMENT_VISIBILITY_NUM.COMPANY,
    [experiment]
  );

  const isPublic = useMemo(
    () => experiment?.visibility === EXPERIMENT_VISIBILITY_NUM.PUBLIC,
    [experiment]
  );

  const showDatasetTab = useMemo(
    () =>
      (isOwner || isOrganization) &&
      ![
        EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR,
        EXPERIMENT_TASK.TEXT_LLM_CLASSIFICATION,
      ].includes(experiment.task),
    [isOwner, isOrganization, isPublic]
  );

  useEffect(() => {
    if (experiment.description && isCommunity && areAllModelsDone) {
      isOwner ? setTab(2) : setTab(1);
    }
  }, [experiment]);

  const updateSendNotificationHandler = (value: boolean, cb: Function) => {
    updateSendNotification(experiment.id, value).then((data) => {
      cb();
    });
  };

  // TODO: Refactor tabs logic

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.experBody}
    >
      <AntTabs
        value={tab}
        centered
        onChange={handleChange}
        className={classes.tabs}
      >
        <AntTab label="Trained models" />
        {showDatasetTab && <AntTab label="Dataset" />}
        <AntTab label="Details" />
      </AntTabs>

      {tab === 0 &&
        (EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION === experiment?.task &&
        (experiment?.status === STATUS_PENDING ||
          experiment?.status === STATUS_IN_QUEUE) ? (
          <Box>
            <GenericResponseMessageR2R2
              notifyMe={experiment.send_notification}
              title={'Your experiment is waiting in the training queue'}
              description={
                <Text variant="paragraph1">
                  It can take up to 24 hours to be trained. <br /> Keep calm and
                  relax!.🧘
                  <br />
                  Why don't you learn{' '}
                  <Link
                    rel="noopener noreferrer"
                    target="_blank"
                    color="secondary"
                    href="https://docs.cogniflow.ai/en/"
                  >
                    what other things you can do with cogniflow?
                  </Link>
                  📖
                </Text>
              }
              onSendNotificationChange={updateSendNotificationHandler}
            />

            {!areAllModelsDone && isOwner && (
              <CancelFooter experimentId={experiment.id} />
            )}
          </Box>
        ) : (
          <>
            {!showModelView && allModelsWaiting && isOwner && (
              <>
                {experiment?.status === STATUS_PENDING && (
                  <GenericResponseMessageR2R2
                    notifyMe={experiment.send_notification}
                    onSendNotificationChange={updateSendNotificationHandler}
                  />
                )}

                {experiment?.status === STATUS_IN_QUEUE && (
                  <GenericResponseMessageR2R2
                    notifyMe={experiment.send_notification}
                    title={ENV_IS_CLOUD ? CLOUD_LOADING_MESSAGE : undefined}
                    onSendNotificationChange={updateSendNotificationHandler}
                  />
                )}
              </>
            )}

            {showModelView && model && experiment?.status < STATUS_RUNNING && (
              <>
                <SimpleMessage type="info" icon={false}>
                  These are the trained ML models that can be used in any web or
                  mobile app.
                </SimpleMessage>
                <ModelView
                  isRecommended={model?.isRecommended}
                  model={model}
                  preprocessing={experiment.preprocessing}
                />
                <Grid
                  item
                  container
                  justify="center"
                  alignItems="center"
                  spacing={1}
                >
                  {models?.length > 1 && (
                    <>
                      <Typography
                        variant="body1"
                        className={classes.tryAnotherMsg}
                      >
                        We have {models?.length} other models
                      </Typography>
                      <Button
                        size="large"
                        color="secondary"
                        variant="outlined"
                        className={classes.tryAnotherBtn}
                        onClick={handleClick}
                      >
                        Try another model
                      </Button>
                    </>
                  )}
                </Grid>
              </>
            )}
            {experiment?.status >= STATUS_RUNNING && model && (
              <>
                <SimpleMessage type="info" icon={false}>
                  These are the trained ML models that can be used in any web or
                  mobile app.
                </SimpleMessage>
                <ModelView
                  isRecommended={model?.isRecommended}
                  model={model}
                  preprocessing={experiment.preprocessing}
                />
                <Grid
                  item
                  container
                  justify="center"
                  alignItems="center"
                  spacing={1}
                >
                  {models?.length > 1 && (
                    <>
                      <Typography
                        variant="body1"
                        className={classes.tryAnotherMsg}
                      >
                        We have {models?.length} other models
                      </Typography>
                      <Button
                        size="large"
                        color="secondary"
                        variant="outlined"
                        className={classes.tryAnotherBtn}
                        onClick={handleClick}
                      >
                        Try another model
                      </Button>
                    </>
                  )}
                </Grid>
              </>
            )}

            {!areAllModelsDone && isOwner && (
              <CancelFooter experimentId={experiment.id} />
            )}
          </>
        ))}
      {tab === 1 &&
        experiment?.models?.length > 0 &&
        (!showDatasetTab ? (
          <Details experiment={experiment} />
        ) : (
          <Dataset experimentId={experiment.id} task={experiment.task} />
        ))}
      {tab === 2 && experiment?.models?.length > 0 && (
        <Details experiment={experiment} />
      )}
      <ModelSelectModal />
    </Grid>
  );
};
