import React, { useState } from 'react';
import { LLMClassificationResponseBox } from '../../TextTest/ResponseBox/LLMClassificationResponseBox/LLMClassificationResponseBox';
import { PredictResponse } from 'types';
import { TaskWrapper } from '../TaskWrapper';
import { Box } from '@material-ui/core';
import { ImageViewer } from 'components/ImageViewer/ImageViewer';
import useStyles from './LLMImageClassification.styles';

interface LLMImageClassificationProps {
  imgUrl: string;
  prediction: PredictResponse;
}

export const LLMImageClassification = ({
  imgUrl,
  prediction,
}: LLMImageClassificationProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <TaskWrapper
      isApplication={false}
      prediction={prediction}
      imagePanel={
        <>
          <Box className={classes.imgContainer} onClick={() => setOpen(true)}>
            <img src={imgUrl} alt="Result" className={classes.imageCaptured} />
          </Box>
          <ImageViewer
            src={imgUrl}
            open={open}
            onClose={() => setOpen(false)}
          />
        </>
      }
      resultsPanel={
        <Box padding="20px">
          <LLMClassificationResponseBox response={prediction} />
        </Box>
      }
    />
  );
};
