import { useMemo } from 'react';
import { useAuthenticationContext } from 'context/AuthenticationContext';

const FEATURE_CREATE_APPLICATION_ID = '54b166d2-4c49-4f69-9873-344da826116a';
const FEATURE_CREATE_COMPANY_ID = '31c47d6c-8260-46f5-ab3c-966ee49187f7';
const FEATURE_REQUIRES_CUSTOM_OPENAI_API_KEY_ID =
  '625eef7c-783c-4a1a-a1fc-01bc55d7cc62';
const FEATURE_UPDATE_TO_GPT_4_ID = '2648a13b-b460-4142-9e3a-e5c440a3bb6f';
const FEATURE_UPDATE_TO_GPT_3_5_16K_ID = '2fda3070-3c07-4754-8ce2-f2172c85d108';
const FEATURE_USE_TEXTRACT_MODEL_ID = '956ab117-7d10-4160-b68f-f8ac570d1fe6';
const FEATURE_WHATSAPP_INTEGRATION_ID = '7cd82515-f98c-4879-b6a8-1103f77876c2';
const FEATURE_CAN_ONLY_USE_LOW_RES_IMG_CLASSIFIER_ID =
  '69bc039b-8ee4-43f1-a7c7-9cc3cee72958';

export const usePermissions = () => {
  const { user } = useAuthenticationContext();

  const canOnlyUseLowResImgClassifier = useMemo(
    () =>
      (user?.permissions || []).some(
        (permission) =>
          permission.id_feature ===
            FEATURE_CAN_ONLY_USE_LOW_RES_IMG_CLASSIFIER_ID &&
          permission.value > 0
      ),
    [user]
  );

  const canCreateApplication = useMemo(
    () =>
      (user?.permissions || []).some(
        (permission) =>
          permission.id_feature === FEATURE_CREATE_APPLICATION_ID &&
          permission.value > 0
      ),
    [user]
  );

  const createApplicationValue = useMemo(
    () =>
      (user?.permissions || []).find(
        (permission) =>
          permission.id_feature === FEATURE_CREATE_APPLICATION_ID &&
          permission.value > 0
      )?.value || 0,
    [user]
  );

  const canCreateCompany = useMemo(
    () =>
      (user?.permissions || []).some(
        (permission) =>
          permission.id_feature === FEATURE_CREATE_COMPANY_ID &&
          permission.value > 0
      ) && user?.is_company_commercial_plan === false, // Only owners can create a company
    [user]
  );

  const requiresCustomOpenAIAPIKey = useMemo(
    () =>
      (user?.permissions || []).some(
        (permission) =>
          permission.id_feature === FEATURE_REQUIRES_CUSTOM_OPENAI_API_KEY_ID &&
          permission.value > 0
      ),
    [user]
  );

  const canUseGPT3_5_16k = useMemo(
    () =>
      (user?.permissions || []).some(
        (permission) =>
          permission.id_feature === FEATURE_UPDATE_TO_GPT_3_5_16K_ID &&
          permission.value > 0
      ),
    [user]
  );

  const canUseGPT4 = useMemo(
    () =>
      (user?.permissions || []).some(
        (permission) =>
          permission.id_feature === FEATURE_UPDATE_TO_GPT_4_ID &&
          permission.value > 0
      ),
    [user]
  );

  const canUseTextractModel = useMemo(
    () =>
      (user?.permissions || []).some(
        (permission) =>
          permission.id_feature === FEATURE_USE_TEXTRACT_MODEL_ID &&
          permission.value > 0
      ),
    [user]
  );

  const canIntegrateWithWhatsApp = useMemo(
    () =>
      (user?.permissions || []).some(
        (permission) =>
          permission.id_feature === FEATURE_WHATSAPP_INTEGRATION_ID &&
          permission.value > 0
      ),
    [user]
  );

  return {
    canOnlyUseLowResImgClassifier,
    createApplicationValue,
    canCreateApplication,
    canCreateCompany,
    requiresCustomOpenAIAPIKey,
    canUseGPT3_5_16k,
    canUseGPT4,
    canUseTextractModel,
    canIntegrateWithWhatsApp,
  };
};
