import React from 'react';
import { Box } from '@material-ui/core';
import { Text } from 'components';
import { BackArrow, IconClose } from 'assets';
import { useHistory, useRouteMatch } from 'react-router-dom';

import useStyles from './CreationHeaderBar.styles';
import {
  PROJECT_TASK,
  PROJECT_TASK_URL_MAP,
  PROJECT_TYPE_LABELS,
  PROJECT_TYPE_URL_MAP,
} from 'types';

const CREATE_PROJECT_TASKS_LABELS: Record<number, string> = {
  [PROJECT_TASK.CHATBOT]: 'ChatBot',
  [PROJECT_TASK.EXTRACTOR]: 'Extractor',
  [PROJECT_TASK.CLASSIFIER_TEXT]: 'Classifier',
  [PROJECT_TASK.LLM_CLASSIFIER_TEXT]: 'GenAI Classifier',
  [PROJECT_TASK.CLASSIFIER_IMAGE]: 'Classifier',
  [PROJECT_TASK.CLASSIFIER_AUDIO]: 'Classifier',
  [PROJECT_TASK.OBJECT_DETECTION]: 'Object Detection',
};

export const CreationHeaderBar = () => {
  const classes = useStyles();

  const matchTask = useRouteMatch('/new-project/:taskUrl');
  const matchType = useRouteMatch('/new-project/:taskUrl/:typeUrl');

  const getBarTexts = () => {
    if (!matchTask) {
      return {
        title: 'Create a new project',
        subtitle: '',
      };
    }

    let type = 0;

    const matchTypeParams = matchType?.params as { typeUrl: string };

    if (matchTypeParams?.typeUrl) {
      type = PROJECT_TYPE_URL_MAP[matchTypeParams.typeUrl];
    }

    const { taskUrl } = matchTask?.params as { taskUrl: string };

    const task = PROJECT_TASK_URL_MAP[taskUrl];

    const taskText = CREATE_PROJECT_TASKS_LABELS[task];

    return {
      title: `Create a new ${taskText} project`,
      // subtitle: `Lorem ipsum dolor sit amet, consectetur adipiscing elit ${
      //   PROJECT_TYPE_LABELS[type] || ''
      // }`,
      subtitle: ``,
    };
  };

  const { title, subtitle } = getBarTexts();

  const { push, goBack } = useHistory();

  const closeHandler = () => {
    if (matchTask || matchType) {
      push('/new-project');
      return;
    }
    push('/projects/dashboard');
  };

  const backHandler = () => {
    goBack();
  };

  return (
    <Box className={classes.wrapper}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        onClick={backHandler}
        className={classes.optionBtn}
      >
        <BackArrow />
        <Text variant="paragraph1" className={classes.optionText}>
          Back
        </Text>
      </Box>

      <Box>
        <Text variant="h3" className={classes.headerText}>
          {title}
        </Text>

        <Text variant="paragraph1" className={classes.headerText}>
          {subtitle}
        </Text>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        onClick={closeHandler}
        className={classes.optionBtn}
      >
        <IconClose />
        <Text variant="paragraph1" className={classes.optionText}>
          Close
        </Text>
      </Box>
    </Box>
  );
};
