import React, { useEffect, useCallback, useMemo, ReactNode } from 'react';
import {
  Grid,
  Typography,
  CircularProgress,
  Container,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CameraCapture, TestDropzone } from 'components';
import { ActionButton } from 'components/ActionButton';

import { useSetRecoilState } from 'recoil';
import { convertBase64ToFile, toBase64File } from 'services';
import { codeImageState } from 'store/models';

import {
  usePredictionContext,
  useUserPlanContext,
  useApplicationContext,
} from 'context';

import { ObjectDetection } from './ObjectDetection';
import { ImageClassification } from './ImageClassification';
import { OCR } from './OCR';

import classnames from 'classnames';

import {
  EXPERIMENT_TASK,
  ErrorDataProps,
  Experiment,
  ExperimentTest,
  PreTrainedExperimentConfig,
  UPLOAD_FAILS_TEXT,
} from 'types';
import { useGetImagePrediction } from 'hooks';

import { ImgDrag } from 'assets';

import useStyles from './imagetest.styles';
import { FaceSimilarity } from './FaceSimilarity';
import { ImageCustomExtractor } from './ImageCustomExtractor';
import { LLMImageClassification } from './LLMImageClassification/LLMImageClassification';

interface Props extends ExperimentTest {
  modelId: string;
  task: number;
  title?: string;
  experiment: Experiment;
}

const acceptFile: { [key: number]: string } = {
  [EXPERIMENT_TASK.IMAGE_OCR]: 'image/*,application/pdf',
  [EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR]: 'image/*,application/pdf',
};

export const ImageTest = React.memo(
  ({
    modelId,
    task,
    title,
    fileInstruction,
    subtitle = null,
    submitText = null,
    application,
    isApplication = false,
    experiment,
  }: Props) => {
    const {
      imgFormat,
      setImgFormatHandler: setImgFormat,
      image,
      setImageHandler: setImage,
      dropFileFails,
      setDropFileFailsHandler: setDropFileFails,
    } = usePredictionContext();
    const setCodeImageParam = useSetRecoilState(codeImageState);
    const {
      openPlanPredictionExceededModal,
      openPlanHasNoPermissionsModal,
      validatePredictingLimitReached,
    } = useUserPlanContext();
    const { application: applicationState } = useApplicationContext();
    const classes = useStyles({ ...applicationState?.config });

    const setUploadFailsTextError = useCallback(
      (text: string) => {
        setDropFileFails(text);
      },
      [setDropFileFails]
    );

    useEffect(() => {
      if (!image) return;

      setCodeImageParam({
        modelId: modelId || '',
        base64: image || 'PasteBase64CodeHere',
        format: imgFormat || 'jpg',
      });
    }, [modelId, image, imgFormat, setCodeImageParam]);

    const onPredictionFails = useCallback(
      ({ status, message }: ErrorDataProps) => {
        if (isApplication) {
          setUploadFailsTextError(
            'The model is not currently available. Please reach out using a different channel or check back later. Thanks.'
          );
        } else {
          if (message.includes('permissions')) openPlanHasNoPermissionsModal();
          else if (status === 401)
            openPlanPredictionExceededModal(isApplication);
          else setUploadFailsTextError(message);
        }
      },
      [openPlanPredictionExceededModal, isApplication, setUploadFailsTextError]
    );

    const onPredictionSuccess = useCallback(() => {
      setUploadFailsTextError('');
    }, [setUploadFailsTextError]);

    const {
      // eslint-disable-next-line
      prediction,
      loading,
      setPredictionHandler,
      cleanPredictionResponse,
    } = useGetImagePrediction({
      image,
      task,
      id: modelId || '',
      imgFormat,
      onPredictionFails,
      validatePredictingLimitReached,
      application,
      isApplication,
      onPredictionSuccess,
      convertToPdf:
        (experiment.config as PreTrainedExperimentConfig)
          ?.convert_pdf_to_image || '',
      ocrEngine:
        (experiment.config as PreTrainedExperimentConfig)?.ocr_engine || '',
    });

    const removePredictState = useCallback(() => {
      cleanPredictionResponse();
      setImage('');
    }, [cleanPredictionResponse, setImage]);

    const onOpenCameraModalClick = () => {
      setUploadFailsTextError('');
    };

    const onCaptureClickHandler = (base64Pic: string) => {
      removePredictState();
      setImage(base64Pic.split(',')[1]);
    };

    const drozoneFileUpload = async (files: File[]) => {
      removePredictState();
      const [file] = files;

      if (!file) {
        setUploadFailsTextError(UPLOAD_FAILS_TEXT);
        return;
      }

      setImgFormat(file.type.split('/')[1]);
      toBase64File(file).then((base64File) => {
        setImage((base64File as string).split(',')[1]);
      });
    };

    const imgUrl = useMemo(
      () => window.URL.createObjectURL(convertBase64ToFile(image || '')),
      [image]
    );

    const ImageResultMap: { [key: number]: ReactNode } = useMemo(
      () => ({
        [EXPERIMENT_TASK.IMAGE_CLASSIFICATION]: (
          <ImageClassification
            prediction={prediction}
            imgUrl={imgUrl}
            task={task}
            showJsonTab={!isApplication}
          />
        ),
        [EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION]: (
          <ObjectDetection
            prediction={prediction}
            imgUrl={imgUrl}
            showJsonTab={!isApplication}
          />
        ),
        [EXPERIMENT_TASK.IMAGE_OCR]: (
          <OCR
            prediction={prediction}
            imgUrl={imgUrl}
            showJsonTab={!isApplication}
          />
        ),
        [EXPERIMENT_TASK.IMAGE_CUSTOM_ENTITY_EXTRACTOR]: (
          <ImageCustomExtractor
            prediction={prediction}
            imgUrl={imgUrl}
            task={task}
          />
        ),
        [EXPERIMENT_TASK.IMAGE_LLM_CLASSIFICATION]: prediction && (
          <LLMImageClassification imgUrl={imgUrl} prediction={prediction} />
        ),
      }),

      [prediction, task, imgUrl, isApplication]
    );

    return task !== EXPERIMENT_TASK.IMAGE_FACE_SIMILARITY ? (
      <Grid
        container
        direction="column"
        className={classnames({
          [classes.other]: !prediction,
        })}
        justify="center"
        alignContent="center"
      >
        <Grid
          style={{
            display: !prediction || loading ? '' : 'none',
            width: '100%',
          }}
        >
          {!isApplication && (
            <Typography className={classes.title}>
              {title ?? 'Test this model'}
            </Typography>
          )}
          <Typography variant="body1" className={classes.subtitle}>
            {subtitle ? (
              <>{subtitle}</>
            ) : (
              <>
                <Typography variant="inherit" className={classes.underlined}>
                  <label
                    htmlFor="dropZoneInput"
                    className={classes.cursorPointer}
                  >
                    Browse your files
                  </label>
                </Typography>{' '}
                {fileInstruction ??
                  'or drag and drop an image to test this model in real-time.'}
              </>
            )}
          </Typography>

          <Grid container justify="center">
            {loading && image ? (
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.loadingContainer}
              >
                <CircularProgress color="primary" />
              </Grid>
            ) : (
              <>
                <TestDropzone
                  dropzoneOptions={{ accept: acceptFile[task] || 'image/*' }}
                  onDrop={drozoneFileUpload}
                  customClass={classes.background}
                >
                  <Grid container direction="column" alignContent="center">
                    <img
                      className={classes.imageCover}
                      src={ImgDrag}
                      alt="Cover"
                    />
                    <Typography
                      variant="body1"
                      className={classes.dragDropTitle}
                    >
                      Drag and drop here
                    </Typography>
                  </Grid>
                </TestDropzone>
                <Grid className={classes.cameraBtnContainer}>
                  <CameraCapture
                    onOpenModalClick={onOpenCameraModalClick}
                    onCaptureClick={onCaptureClickHandler}
                    colorButton={application?.config?.colorButton}
                    submitText={submitText}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        {prediction && (
          <Container maxWidth="lg" style={{ padding: 0 }}>
            <Grid container justify="center">
              {ImageResultMap[task]}
            </Grid>
            <ActionButton
              onClick={removePredictState}
              variant="contained"
              color="secondary"
              size="large"
              className={classes.cameraBtnContainer}
            >
              New Test
            </ActionButton>
          </Container>
        )}
        <Grid className={classes.alertWrapper}>
          {dropFileFails && (
            <Alert severity="error" onClose={() => setUploadFailsTextError('')}>
              <div dangerouslySetInnerHTML={{ __html: dropFileFails }}></div>
            </Alert>
          )}
        </Grid>
      </Grid>
    ) : (
      <>
        <FaceSimilarity
          prediction={prediction}
          setPredictionHandler={setPredictionHandler}
          modelId={modelId}
          task={task}
          onPredictionFails={onPredictionFails}
          application={application}
          isApplication={isApplication}
          subtitle={subtitle}
          submitText={submitText}
        />
        {dropFileFails && (
          <Alert severity="error" onClose={() => setUploadFailsTextError('')}>
            {dropFileFails}
          </Alert>
        )}
      </>
    );
  }
);
