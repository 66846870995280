import React, { useState, useEffect } from 'react';
import { IconButton, makeStyles, Fade } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface ImageViewerProps {
  src: string;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1300,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: '#fff',
  },
  image: {
    maxWidth: '90%',
    maxHeight: '90vh',
    objectFit: 'contain',
  },
}));

export const ImageViewer: React.FC<ImageViewerProps> = ({
  src,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => setShowImage(true), 75);
      return () => clearTimeout(timer);
    } else {
      setShowImage(false);
    }
  }, [open]);

  if (!open) return null;

  return (
    <div className={classes.overlay} onClick={onClose}>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <Fade in={showImage} timeout={300}>
        <img
          src={src}
          alt="Enlarged view"
          className={classes.image}
          onClick={(e) => e.stopPropagation()}
        />
      </Fade>
    </div>
  );
};
