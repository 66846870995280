import { EXPERIMENT_TASK, ExperimentLabel, PROJECT_TYPE } from 'types';

const taskTitleLabels: ExperimentLabel = {
  [EXPERIMENT_TASK.TEXT_QANDA]: 'Upload your documents',
  [EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR]:
    'Add entities to specify the information you want to extract',
  [EXPERIMENT_TASK.TEXT_LLM_CLASSIFICATION]:
    'Add categories to specify the information you want to classify',
};

const taskSubtitlesLabels: ExperimentLabel = {
  [EXPERIMENT_TASK.TEXT_QANDA]:
    'Train your chatbot to respond based on your documents.',
  [EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR]: '',
  [EXPERIMENT_TASK.TEXT_CLASSIFICATION]:
    'This model training requires data in a CSV format.\nPlease make sure you match the structure or use the examples.',
  [EXPERIMENT_TASK.IMAGE_CLASSIFICATION]:
    'This model training requires images in a ZIP file.\nPlease make sure you match the structure or use the examples.',
  [EXPERIMENT_TASK.AUDIO_CLASSIFICATION]:
    'This model training requires audios in a ZIP file.\nPlease make sure you match the structure or use the examples.',
};

type TextsMap = { [key: number]: { title: string; subtitle: string } };

type GetTextsProps = {
  task: number;
  type: number;
};

export const getTexts = ({ task, type }: GetTextsProps) => {
  const textsMap: TextsMap = {
    [PROJECT_TYPE.TEXT]: {
      title: taskTitleLabels[task] || 'Upload your data',
      subtitle:
        taskSubtitlesLabels[task] ||
        'Train your own AI model from samples you provide',
    },
    [PROJECT_TYPE.IMAGE]: {
      title: taskTitleLabels[task] || 'Upload your data',
      subtitle:
        taskSubtitlesLabels[task] ||
        'Train your own AI model from samples you provide',
    },
    [PROJECT_TYPE.AUDIO]: {
      title: taskTitleLabels[task] || 'Upload your data',
      subtitle:
        taskSubtitlesLabels[task] ||
        'Train your own AI model from samples you provide',
    },
  };

  return textsMap[type] || {};
};
