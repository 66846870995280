import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  Hidden,
  Typography,
  Box,
} from '@material-ui/core';
import { useRecoilState } from 'recoil';
import { currentModelState } from 'store/models';
import { currentExperimentState } from 'store/experiments';
import { Link, useHistory, useParams, Switch } from 'react-router-dom';
import { ImageTest } from './ModelTests/ImageTest';
import { TextTest } from './ModelTests/TextTest';
import { AudioTest } from './ModelTests/AudioTest';
import { CodeTab } from './CodeTab';
import { BackArrow } from 'assets';
import { getModel } from 'services/models';
import { getExperiment } from 'services/experiments';
import { AntTab, AntTabs } from 'components/Tabs';
import { ShareModel } from './ShareModel/ShareModel';
import { Application, EXPERIMENT_TASK, EXPERIMENT_TYPE } from 'types';

import { FullwidthBarAlert } from 'components/AlertToast';
import {
  isQAGenerative,
  isQAOld,
  getQAGenerativeUrlByModel,
  isQAGenerativeStructured,
} from 'utils/experiment';
import { useAuthenticationContext } from 'context';

import { Settings } from './Settings';
import { Alert } from '@material-ui/lab';
import { toast } from 'react-toastify';

import { AuthRoute } from 'components';

import useStyle from './testexperiment.styles';
import { getApplicationByModel } from 'services/application';
import { Documents } from './Documents';
import { LongTranscription } from './LongTranscription';

enum TABS {
  SETTINGS = 'settings',
  TEST = 'test',
  INTEGRATION = 'integration',
  WEBAPP = 'webapp',
  CHAT = 'chat',
  DOCUMENTS = 'documents',
  LONG_TRANSCRIPTION = 'long-transcription',
}

export const TestExperiment = ({ match: { path } }: any) => {
  const classes = useStyle();
  const { user, token } = useAuthenticationContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [currentModel, setModel] = useRecoilState(currentModelState);
  const [currentExperiment, setExperiment] = useRecoilState(
    currentExperimentState
  );
  const { modelId, tab, id: experimentId } = useParams<{
    modelId: string;
    tab: string;
    id: string;
  }>();
  const { replace } = useHistory();

  const [idApplication, setIdApplication] = useState<string | null>(null);
  const [application, setApplication] = useState<Application | null>(null);

  const [webbappLoading, setWebappLoading] = useState<boolean>(false);

  const setWebappLoadingHandler = (value: boolean) => {
    setWebappLoading(value);
  };

  const onSettingsSaveHandler = () => {
    toast.success('Configuration saved successfully', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const onEventSuccess = (message: string) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  useEffect(() => {
    setLoading(true);
    const fetchModel = async () => {
      try {
        const model = await getModel(modelId);
        setModel(model);
        const exp = await getExperiment(model.id_experiment);
        setExperiment(exp);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        setModel({} as any);
      }
    };
    fetchModel();
  }, [setModel, modelId, setExperiment]);

  const handleChange = useCallback(
    (e, newValue) => {
      replace(`${newValue}`);
    },
    [replace]
  );

  const TestViewsMap = useMemo(
    () =>
      currentExperiment
        ? {
            [EXPERIMENT_TYPE.TEXT]: (
              <TextTest
                modelId={(currentModel && currentModel?.id) || ''}
                experiment={currentExperiment}
                task={currentExperiment.task}
              />
            ),
            [EXPERIMENT_TYPE.IMAGE]: (
              <ImageTest
                modelId={(currentModel && currentModel?.id) || ''}
                task={currentExperiment.task}
                experiment={currentExperiment}
              />
            ),
            [EXPERIMENT_TYPE.AUDIO]: (
              <AudioTest
                modelId={(currentModel && currentModel?.id) || ''}
                experiment={currentExperiment}
                task={currentExperiment.task}
              />
            ),
          }
        : '',
    [currentModel, currentExperiment]
  );

  const [showQAOldAlert, setShowQAOldAlert] = useState<boolean>(false);

  const expIsQAGen = useMemo(() => isQAGenerative(currentExperiment), [
    currentExperiment,
  ]);

  const expIsQAGenStr = useMemo(
    () => isQAGenerativeStructured(currentExperiment),
    [currentExperiment]
  );

  const QAGenerativeUrl = useMemo(
    () =>
      currentModel &&
      token &&
      getQAGenerativeUrlByModel(currentModel?.id, token),
    // eslint-disable-next-line
    [currentModel]
  );

  const isLongTranscription =
    EXPERIMENT_TASK.AUDIO_SPEECH2TEXT === currentExperiment?.task;

  useEffect(() => {
    setShowQAOldAlert(isQAOld(currentExperiment));
  }, [currentExperiment]);

  useEffect(() => {
    if (expIsQAGen) replace(TABS.CHAT);
    // eslint-disable-next-line
  }, [expIsQAGen]);

  const isExtractor =
    EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR === currentExperiment?.task;
  const isZeroClassification =
    EXPERIMENT_TASK.TEXT_LLM_CLASSIFICATION === currentExperiment?.task;

  const isOwner = currentExperiment?.id_user === user?.id;

  useEffect(() => {
    const fetchData = async () => {
      if (!currentModel?.id) return;

      setLoading(true);
      try {
        const application = await getApplicationByModel(currentModel.id);
        setApplication(application);
        setIdApplication(application?.id);
      } catch {
        setIdApplication(null);
      }
      setLoading(false);
    };
    fetchData();
  }, [currentModel, setLoading]);

  const goBack = useCallback(() => {
    replace(`/experiment/${experimentId}`);
  }, [replace]);

  const showSettingsTab = expIsQAGen || isExtractor || isZeroClassification;

  return (
    <div className={classes.container}>
      {/* <ToastContainer autoClose={2000} /> */}
      {showQAOldAlert && (
        <Box mb="12px" width="100%">
          <FullwidthBarAlert
            showing={showQAOldAlert}
            message={
              <span>
                ✨ You are using an OLD version of the question-answering model.
                We suggest you train a new version using our new ChatGPT-like
                chat model{' '}
                <Link
                  to="/create/text"
                  style={{ color: '#fff', fontWeight: 'bold' }}
                >
                  here!
                </Link>
              </span>
            }
            severity="warning"
            resetAlert={() => setShowQAOldAlert(false)}
            floating={false}
          />
        </Box>
      )}
      <Grid
        style={{ padding: '20px 0' }}
        container
        item
        md={12}
        lg={12}
        xs={12}
        justify="center"
        alignContent="flex-start"
      >
        {loading && (
          <Grid container justify="center" alignItems="center">
            <CircularProgress color="primary" />
          </Grid>
        )}
        {currentModel && currentExperiment && (
          <Grid container direction="column" wrap="nowrap">
            <Box className={classes.btnBack}>
              <Button
                size="large"
                onClick={goBack}
                color="secondary"
                variant="outlined"
              >
                <Grid container justify="center" alignItems="center">
                  <BackArrow />
                  <Hidden smDown>Back to experiment</Hidden>
                </Grid>
              </Button>
            </Box>
            <Grid container justify="center" alignItems="center">
              <Grid container item md="auto" direction="column">
                <Typography variant="h1">Use Model</Typography>
                <Typography variant="body1" className={classes.modelNameTitle}>
                  {currentExperiment?.title}
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.mainContainer}>
              <AntTabs
                value={tab}
                centered
                onChange={handleChange}
                className={classes.tabsElement}
              >
                {expIsQAGen && !expIsQAGenStr && isOwner && (
                  <AntTab chip="NEW" label="Documents" value={TABS.DOCUMENTS} />
                )}

                {isLongTranscription && (
                  <AntTab
                    chip="NEW"
                    label="Long Transcription"
                    value={TABS.LONG_TRANSCRIPTION}
                  />
                )}

                {showSettingsTab && (
                  <AntTab label="Settings" value={TABS.SETTINGS} chip="NEW" />
                )}

                {expIsQAGen && (
                  <AntTab label="Chat" value={TABS.CHAT} chip="NEW" />
                )}

                <AntTab label="Test" value={TABS.TEST} />
                <AntTab label="Integration" value={TABS.INTEGRATION} />

                <AntTab
                  label={expIsQAGen ? 'Deploy' : 'Web App'}
                  value={TABS.WEBAPP}
                />
              </AntTabs>

              <Switch>
                <AuthRoute
                  exact
                  path={`${path}/${TABS.SETTINGS}`}
                  component={() => (
                    <Settings
                      experiment={currentExperiment}
                      onSave={onSettingsSaveHandler}
                    />
                  )}
                />
                <AuthRoute
                  exact
                  path={`${path}/${TABS.LONG_TRANSCRIPTION}`}
                  component={() => (
                    <LongTranscription
                      modelId={modelId}
                      task={currentExperiment.task}
                      onLongTranscriptionSuccess={onEventSuccess}
                    />
                  )}
                />

                <AuthRoute
                  exact
                  path={`${path}/${TABS.TEST}`}
                  render={() => (
                    <Grid>{TestViewsMap[currentExperiment?.type]}</Grid>
                  )}
                />

                <AuthRoute
                  exact
                  path={`${path}/${TABS.INTEGRATION}`}
                  render={() => (
                    <Grid container item>
                      <CodeTab
                        type={currentExperiment?.type || 0}
                        task={currentExperiment?.task}
                        application={application}
                        model={currentModel}
                      />
                    </Grid>
                  )}
                />

                <AuthRoute
                  exact
                  path={`${path}/${TABS.WEBAPP}`}
                  render={() => (
                    <Box>
                      <ShareModel
                        experiment={currentExperiment}
                        idModel={currentModel.id}
                        type={currentExperiment?.type}
                        idApplication={idApplication}
                        application={application}
                        setApplication={setApplication}
                        setIdApplication={setIdApplication}
                        loading={webbappLoading}
                        setLoading={setWebappLoadingHandler}
                      />
                    </Box>
                  )}
                />
                <AuthRoute
                  exact
                  path={`${path}/${TABS.CHAT}`}
                  render={() => (
                    <Box>
                      <iframe
                        src={QAGenerativeUrl}
                        frameBorder="0"
                        width="100%"
                        height="680"
                        style={{ marginTop: -24 }}
                      ></iframe>
                      <Box maxWidth={900} style={{ margin: '0 auto' }}>
                        <Alert severity="info">
                          Your chatbot interface customization won’t be
                          reflected in this view. To see the latest changes,
                          click the link under the tab <strong>Deploy</strong>.
                        </Alert>
                      </Box>
                    </Box>
                  )}
                />

                <AuthRoute
                  exact
                  path={`${path}/${TABS.DOCUMENTS}`}
                  render={() => (
                    <Documents
                      idModel={currentModel.id}
                      idExperiment={currentExperiment.id}
                    />
                  )}
                />
              </Switch>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
