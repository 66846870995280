import { PROJECT_TASK, PROJECT_TASK_URL, PROJECT_TYPE_URL } from 'types';

export const useSelectType = () => {
  const typeCards: Record<number, any[]> = {
    [PROJECT_TASK.CHATBOT]: [
      {
        title: 'Documents',
        description:
          'Upload .pdf, .docx, .doc or .txt files as knowledge base.',
        image: 'document',
        type: PROJECT_TYPE_URL.TEXT,
      },
      {
        title: 'Spreadsheet',
        description: 'Upload a spreadheet (.csv file) to train a chatbot',
        image: 'spreadsheet',
        type: PROJECT_TYPE_URL.TEXT,
        url: 'https://tally.so/r/nGpevk',
      },
    ],
    [PROJECT_TASK.EXTRACTOR]: [
      {
        title: 'Plain Text',
        description:
          "Extract key information from any text, whether it's sourced from emails, personal notes, or documents.",
        image: 'text',
        type: PROJECT_TYPE_URL.TEXT,
      },
      {
        title: 'Image or PDF',
        description:
          'Extract key information efficiently from images like receipts or from digital or scanned PDFs.',
        image: 'image',
        type: PROJECT_TYPE_URL.IMAGE,
      },
    ],
    [PROJECT_TASK.CLASSIFIER_TEXT]: [
      {
        title: 'Text',
        description: 'Create a project to classify texts by assigning labels.',
        image: 'text',
        type: PROJECT_TYPE_URL.TEXT,
        subTypesTitle: 'Select Your Classification Method',
        subTypes: [
          {
            title: 'Traditional Classifier',
            description: 'Using dataset.',
            image: 'text',
            route: `/new-project/${PROJECT_TASK_URL.CLASSIFIER}/${PROJECT_TYPE_URL.TEXT}`,
          },
          {
            title: 'GenAI Classifier',
            description:
              'LLM-based, no dataset required.<div style="height: 13px"></div>',
            image: 'text',
            route: `/new-project/${PROJECT_TASK_URL.LLM_CLASSIFIER}/${PROJECT_TYPE_URL.TEXT}`,
          },
        ],
      },
      {
        title: 'Image',
        description: 'Create a project to classify photos, images, or graphs.',
        image: 'image',
        type: PROJECT_TYPE_URL.IMAGE,
        subTypesTitle: 'Select Your Classification Method',
        subTypes: [
          {
            title: 'Traditional Classifier',
            description: 'Using dataset.',
            image: 'image',
            route: `/new-project/${PROJECT_TASK_URL.CLASSIFIER}/${PROJECT_TYPE_URL.IMAGE}`,
          },
          {
            title: 'GenAI Classifier',
            description:
              'LLM-based, no dataset required.<div style="height: 13px"></div>',
            image: 'image',
            route: `/new-project/${PROJECT_TASK_URL.LLM_CLASSIFIER}/${PROJECT_TYPE_URL.IMAGE}`,
          },
        ],
      },
      {
        title: 'Audio',
        description: 'Classify sounds with predefined categories',
        image: 'audio',
        type: PROJECT_TYPE_URL.AUDIO,
        route: `/new-project/${PROJECT_TASK_URL.CLASSIFIER}/${PROJECT_TYPE_URL.AUDIO}`,
      },
    ],

    [PROJECT_TASK.OBJECT_DETECTION]: [
      {
        title: 'Image',
        description: 'Classify images, detect objects and more.',
        image: 'image',
        type: PROJECT_TYPE_URL.IMAGE,
      },
    ],
  };
  return {
    typeCards,
  };
};
