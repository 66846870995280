import React from 'react';
import { Box } from '@material-ui/core';
import { ActionButton, Text } from 'components';
import ImportIcon from '@material-ui/icons/GetApp';
import { importJsonFile } from 'utils';
import { PROJECT_TASK } from 'types';

interface ImportSettingsProps {
  onImport: (data: any) => void;
  onCancel: () => void;
  task: number;
}

export const IMPORT_SETTINGS_MAP: Record<number, any> = {
  [PROJECT_TASK.EXTRACTOR]: {
    title: 'Entities',
    subtitle: 'No entities added',
    description:
      'No entities are defined to extract. Add each entity manually or from a JSON file.',
    editButtonText: 'Add manually',
    importButtonText: 'Import from JSON file',
  },
  [PROJECT_TASK.LLM_CLASSIFIER_IMAGE]: {
    title: 'Categories',
    subtitle: 'No categories added',
    description:
      'No categories are defined to classify. Add each category manually or from a JSON file.',
    editButtonText: 'Add manually',
    importButtonText: 'Import from JSON file',
  },
};

const ImportSettings: React.FC<ImportSettingsProps> = ({
  onImport,
  onCancel,
  task,
}) => {
  const importSettings = IMPORT_SETTINGS_MAP[task];

  const handleImport = () => {
    try {
      importJsonFile((importedData) => {
        if (!importedData) return;
        onImport(importedData);
      });
    } catch (error) {
      console.error('Error importing JSON file:', error);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gridGap="18px"
      margin="16px"
    >
      {importSettings.title && (
        <Text variant="h3" align="left" style={{ width: '100%' }}>
          {importSettings.title}
        </Text>
      )}
      <Text variant="paragraph1">
        <strong>{importSettings.subtitle}</strong>
      </Text>
      <Text variant="paragraph1" align="center">
        {importSettings.description}
      </Text>
      <div></div>
      <Box display="flex" justifyContent="flex-end" gridGap="12px">
        <ActionButton
          size="large"
          variant="contained"
          color="secondary"
          onClick={onCancel}
          style={{ boxShadow: 'none' }}
        >
          {importSettings.editButtonText}
        </ActionButton>
        <ActionButton
          size="large"
          variant="outlined"
          color="secondary"
          onClick={handleImport}
          startIcon={<ImportIcon />}
          style={{ boxShadow: 'none' }}
        >
          {importSettings.importButtonText}
        </ActionButton>
      </Box>
    </Box>
  );
};

export default ImportSettings;
