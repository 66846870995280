import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  imageCaptured: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    cursor: 'pointer',
  },
}));
