import React from 'react';
import { Box } from '@material-ui/core';
import { SelectTask } from './SelectTask';
import { Route, Switch } from 'react-router-dom';
import { SelectType } from './SelectType';
import { CreationHeaderBar } from './CreationHeaderBar';
import { TrainingData } from './TrainingData';
import { ProjectCreationProvider } from 'context';
import { Ready } from './Ready';

export const CreateProject = ({ match: { path } }: any) => {
  return (
    <Box>
      <ProjectCreationProvider>
        <CreationHeaderBar></CreationHeaderBar>
        <Box my="36px">
          <Switch>
            <Route exact path={path} component={SelectTask} />
            <Route exact path={`${path}/:taskUrl`} component={SelectType} />
            <Route
              exact
              path={`${path}/:taskUrl/:typeUrl`}
              component={TrainingData}
            />

            <Route
              exact
              path={`${path}/:taskUrl/:typeUrl/ready`}
              component={Ready}
            />
          </Switch>
        </Box>
      </ProjectCreationProvider>
    </Box>
  );
};
