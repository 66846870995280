import React, { ErrorInfo } from 'react';
import ErrorBoundary from './ErrorBoundary';

const withErrorBoundary = (
  WrappedComponent: React.ComponentType<any>,
  fallback?: React.ReactNode,
  onError?: (error: Error, errorInfo: ErrorInfo) => void
): React.FC<any> => {
  return (props) => (
    <ErrorBoundary fallback={fallback} onError={onError}>
      <WrappedComponent {...props} />
    </ErrorBoundary>
  );
};

export default withErrorBoundary;
