import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  categoryHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  chip: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  score: {
    minWidth: '40px',
    textAlign: 'right',
    marginRight: theme.spacing(1),
  },
  reasoning: {
    fontStyle: 'italic',
    color: '#505469de',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  progress: {
    width: '80px',
    marginLeft: theme.spacing(1),
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  categoryName: {
    marginRight: theme.spacing(1),
  },
  emptyMessage: {
    textAlign: 'center',
    margin: '12px 0',
    color: '#272c6a73',
    fontSize: '14px',
    fontWeight: 500,
  },
}));
